.footer {
  background-color: #838fa4;
  color: rgba(#ffffff, 0.89);
  @include media('<tablet') {
    padding: 0;
  }
  a {
    color: inherit;
    transition: color 0.3s ease-in-out;
    &:hover {
      color: $black;
    }
  }
}

.footer-top {
  position: relative;
  padding: 30px 1.5rem 15px;
  @include media('>=tablet') {
    padding: 62px 1.5rem 28px;
  }
}

.footer-logo {
  margin-bottom: 37px;
  @include media('<tablet') {
    margin-bottom: 20px;
    text-align: center;
  }
  img {
    @include media('<tablet') {
      max-width: 170px;
    }
  }
}

.footer-bottom {
  color: $white;
  background-color: #5b677c;
  padding: 20px 1.5rem;
  @include media('>=tablet') {
    padding: 40px 1.5rem;
  }
  .container {
    @include media('>=tablet') {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

.secondary-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 10px;
  @include media('>=tablet') {
    padding-bottom: 36px;
  }
  li {
    padding: 0 10px;
    margin-bottom: 15px;
    position: relative;
    @include media('>=tablet') {
      padding: 0 23px;
    }
    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 14px;
      background: rgba($white, 0.2);
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
    &:last-child {
      &:before {
        display: none;
      }
    }
  }
  .current_page_item {
    & > a {
      color: $black;
    }
  }
}

.footer-nav {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
  padding-top: 0.5rem;
  @include media('>=tablet') {
    padding-top: 0;
    flex-direction: row;
  }
  li {
    padding: 0 15px;
    position: relative;
    margin-bottom: 0.5rem;
    @include media('>=tablet') {
      margin-bottom: 0;
    }
    &:before {
      @include media('>=tablet') {
        content: '';
        display: block;
        width: 1px;
        height: 14px;
        background: rgba($white, 0.2);
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
    &:last-child {
      @include media('<tablet') {
        margin-bottom: 0;
      }
    }
  }
  .current_page_item {
    & > a {
      color: $black;
    }
  }
}

.social-links {
  @extend %listreset;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include media('>=tablet') {
    font-size: 1.3rem;
  }
  a {
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: rgba(#5b677c, 0.6);
    transition: background 0.3s ease-in-out;
    @include media('>=tablet') {
      width: 50px;
      height: 50px;
    }
    &:hover {
      color: $white;
      background-color: rgba(#5b677c, 1);
    }
  }
  li {
    padding: 0 3px;
    margin-bottom: 15px;
  }
}
