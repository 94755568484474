// #main {
// 	@extend %clearfix;
// }

// #content {
// 	float: left;
// 	width: 70%;
// 	&:only-child{
// 		float:none;
// 		width:100%;
// 	}
// }

// #sidebar {
// 	float: right;
// 	width: 25%;
// 	#wp-calendar {
// 		width:100%;
// 	}
// }

// footer-at-bottom start
@include media('≥tablet') {
  .page-holder {
    height: 100%;
    display: table;
    table-layout: fixed;
    overflow: hidden;
    width: 100%;
  }
  #wrapper {
    display: table-cell;
    margin: 0 auto;
  }

  #footer {
    overflow: hidden; /*for FF on Windows 7*/
    display: table-footer-group;
    height: 1%;
  }
}
// footer-at-bottom end

.widget {
  margin-bottom: $vertical-rhythm;
  @extend %clearfix;
  ul {
    padding-left: 2em;
  }
  select {
    min-width: 150px;
  }
}

.comment-form {
  label {
    display: block;
    padding-bottom: 4px;
  }
  textarea {
    overflow: auto;
    margin: 0;
    height: 120px;
    min-height: 120px;
  }
  input[type='tel'],
  input[type='email'],
  input[type='search'],
  input[type='password'],
  input[type='url'],
  textarea,
  input[type='text'] {
    margin: 0 4px 0 0;
    max-width: 300px;
    width: 100%;
  }
  input[type='submit'] {
    display: block;
    float: none;
  }
  .comment-form-cookies-consent {
    display: flex;
    align-items: center;
    input[type='checkbox'] {
      margin-right: 10px;
    }
  }
}

.post-password-form {
  label {
    display: block;
    margin-bottom: 4px;
  }
  input[type='password'] {
    margin: 0 4px 0 0;
    max-width: 300px;
    width: 100%;
  }
}

.search-form {
  @extend %clearfix;
  margin-bottom: 15px;
  input {
    float: left;
    line-height: 2;
    display: block;
    padding: 1px 10px;
    border: 1px solid;
    border-radius: 8px;
    &[type='search'] {
      margin: 0 4px 4px 0;
      width: 150px;
    }
  }
}

.post-password-form {
  p {
    @extend %clearfix;
  }
  input {
    float: left;
    height: 2em;
    &[type='password'] {
      width: 150px;
    }
  }
}

.comment-form,
.post-password-form,
.search-form {
  input[type='submit'] {
    background: $btn-background;
    color: $btn-color;
    height: auto;
    border: none;
    display: block;
    line-height: 2;
    padding: 2px 0.5em;
    transition: background 0.3s ease-in-out;
    &:hover {
      background: $gray;
    }
  }
}

.commentlist {
  margin: 0 0 $vertical-rhythm;
  .edit-link {
    margin: 0;
  }
  .avatar-holder {
    float: left;
    margin: 0 $vertical-rhythm 4px 0;
  }
}

.commentlist-item {
  .commentlist-item {
    padding: 0;
    @include media('>=tablet') {
      padding: 0 0 0 2em;
    }
  }
}

.comment,
.commentlist-holder {
  overflow: hidden;
}

.commentlist-item .commentlist-item,
.commentlist-item + .commentlist-item {
  padding-top: $vertical-rhythm;
}

.widget_calendar {
  #next {
    text-align: right;
  }
}

.gallery-item {
  dt {
    float: none;
    padding: 0;
  }
}

.navigation,
.navigation-comments {
  margin-bottom: $vertical-rhythm;
  width: 100%;
  justify-content: center;
  position: relative;
  vertical-align: middle;

  .screen-reader-text {
    position: absolute;
    left: -99999px;
  }

  .nav-links {
    width: 100%;
  }

  .page-numbers {
    display: inline-block;
    vertical-align: middle;
    padding: 0 0.4em;
    @include media('<tablet') {
      padding: 0 3px;
    }
  }

  .next,
  .prev {
    display: inline-block;
    vertical-align: middle;
    max-width: 48%;
    background: $btn-background;
    color: $btn-color;
    padding: 0.4em;
    border-radius: 8px;
    text-decoration: none;
    white-space: nowrap;
    transition: background 0.3s ease-in-out;
    @include media('<tablet') {
      padding: 0.2em;
    }
    &:hover {
      background: $gray;
    }
  }
}

.navigation-single {
  @extend %clearfix;
  margin-bottom: $vertical-rhythm;
  .next,
  .prev {
    float: left;
    vertical-align: middle;
    max-width: 48%;
    background: $btn-background;
    color: $btn-color;
    text-decoration: none;
    border-radius: 8px;
    transition: background 0.3s ease-in-out;
    &:hover {
      background: $gray;
    }
    a {
      color: inherit;
      text-decoration: none;
      padding: 0.4em;
      display: block;
      @include media('<tablet') {
        padding: 0.2em;
      }
    }
  }
  .next {
    float: right;
  }
}

.wp-caption {
  max-width: 100%;
  clear: both;
  border: 1px solid $gray;
  padding: 0.4em;
  margin-bottom: $vertical-rhythm;
  img {
    margin: 0;
    vertical-align: top;
  }
  p {
    margin: 0.4em 0 0;
  }
}

div.aligncenter {
  margin: 0 auto $vertical-rhythm;
}

img.aligncenter {
  display: block;
  margin: 0 auto;
}

.alignleft {
  float: left;
  margin: 0 $vertical-rhythm 4px 0;
}

.alignright {
  float: right;
  margin: 0 0 4px $vertical-rhythm;
}

.mejs-container {
  margin-bottom: $vertical-rhythm;
}

.wp-caption-text,
.gallery,
.alignnone,
.gallery-caption,
.sticky,
.bypostauthor {
  height: auto;
}

iframe {
  max-width: 100%;
}
.navigation-single .next,
.navigation-single .prev,
.page-numbers,
.title {
  word-break: break-word;
}

#wp-calendar {
  tbody {
    td {
      text-align: center;
    }
  }
  td,
  th {
    border: 1px solid $gray;
  }
}
