.button {
  color: $primary;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 1.1px;
  box-shadow: 3px 4px 43px rgba(0, 0, 0, 0.14);
  background-color: #f8f9fb;
  padding: 8px 20px;
  height: auto;
  max-width: 100%;
  white-space: normal;
  text-transform: uppercase;
  transition: background 0.3s ease-in-out;
  @include media('>=tablet') {
    font-size: 20px;
    line-height: 30px;
  }
  @include media('>=desktop') {
    font-size: 22px;
    line-height: 49px;
    padding: 12px 35px;
  }
  &.is-primary {
    background: $primary;
    &:hover {
      background: $gray;
    }
  }
  &.is-info {
    background-color: $gray;
    &:hover {
      background: $primary;
    }
  }
  &.is-small {
    font-size: 16px;
    line-height: 20px;
    padding: 8px 20px;
    @include media('>=tablet') {
      font-size: 18px;
      line-height: 38px;
    }
    @include media('>=desktop') {
      font-size: 20px;
      line-height: 40px;
      padding: 9px 25px;
    }
    @include media('>=fullhd') {
      padding: 9px 45px;
    }
    &:not(.is-rounded) {
      border-radius: 5px;
    }
  }
  &:not(.is-rounded) {
    border-radius: 5px;
  }
}
