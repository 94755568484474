#wrapper {
  max-width: 100%;
  overflow: hidden;
  position: relative;
}

.accessibility {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.accessibility:focus {
  position: static;
  width: auto;
  height: auto;
}

.bg-stretch {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.container:not(.is-max-desktop):not(.is-max-widescreen) {
  @include media('>=fullhd') {
    max-width: 1302px;
  }
}

.hero-section {
  &.content {
    margin-bottom: 0;
    p {
      margin-bottom: 1.1em;
    }
  }
  h1 {
    &:not(:first-child) {
      margin-top: 0;
    }
  }
  .subtitle {
    color: $primary;
    display: block;
    font-weight: 300;
    font-size: 20px;
    line-height: 1.57;
    margin-bottom: 1px;
    @include media('>=tablet') {
      font-size: 35px;
    }
  }
}

.info-banner {
  color: $white;
  font-weight: 300;
  &.content {
    margin-bottom: 0;
    h2 {
      font-weight: 300;
      margin-bottom: 0.7rem;
      color: inherit;
    }
  }
}

.content {
  h1 {
    font-weight: 900;
    margin-bottom: 0.19em;
    @include media('>=tablet') {
      font-size: 2.6em;
    }
  }
  h2 {
    @include media('>=tablet') {
      font-size: 1.5em;
    }
  }
  &.is-medium {
    @include media('>=desktop') {
      font-size: 1.875rem;
    }
  }
  &.is-large {
    @include media('>=desktop') {
      font-size: 1.563rem;
    }
  }
}

.hero {
  &.is-large {
    .hero-body {
      @include media('>=tablet') {
        padding: 9rem 1.5rem;
      }
    }
  }
  &.is-medium {
    .hero-body {
      @include media('>=tablet') {
        padding: 9rem 1.5rem;
      }
    }
  }
}

.hero-body {
  &.container {
    width: 100%;
  }

  @include media('<tablet') {
    background: #d8dadf url('@images/gradient_hero.png');
  }
}

.has-background-info-light {
  background-color: #f8f9fb !important;
}

.four-columns-block {
  &.content {
    margin-bottom: 0;
    h2 {
      font-weight: 400;
      line-height: 1.11;
      margin-bottom: 2rem;
      @include media('>=desktop') {
        font-size: 2.815rem;
        margin-bottom: 3.2rem;
      }
    }
    h3 {
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 1rem;
      @include media('>=desktop') {
        font-size: 26px;
        line-height: 1.23;
        margin-bottom: 23px;
      }
    }
  }
  &.section {
    @include media('>=tablet') {
      padding: 4.8rem 1.5rem 5.5rem;
    }
  }
  .img-holder {
    min-height: 100px;
    margin-bottom: 1.5rem;
    @include media('>=tablet') {
      margin-bottom: 33px;
    }
  }
  .column-content {
    border-radius: 8px;
    padding: 25px 15px;
    box-shadow: 3px 4px 43px rgba(0, 0, 0, 0.06);
    @include media('>=tablet') {
      padding: 46px 15px;
    }
  }
  &:not(:last-child) {
    margin-bottom: 0;
  }
  .badge {
    position: absolute;
    left: 0;
    margin-left: -23px;
    width: 120px;
    margin-top: -10px;
  }
}

.logo-section {
  &.content {
    margin-bottom: 0;
    h2 {
      margin-bottom: 2rem;
      line-height: 1.22;
      font-weight: 400;
      @include media('>=desktop') {
        font-size: 2.815rem;
        max-width: 85%;
        margin: 0 auto 3.7rem;
      }
    }
  }
  &.section {
    @include media('>=tablet') {
      padding: 5.2rem 1.5rem 5.7rem;
    }
  }
  .logos-list {
    @extend %listreset;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    @include media('>=desktop') {
      justify-content: space-between;
    }
    li {
      padding: 0 10px;
      margin-bottom: 1rem;
      @include media('<tablet') {
        max-width: 45%;
      }
      @include media('>=tablet') {
        margin-bottom: 33px;
      }
    }
    li:marker {
      display: none;
    }
    img {
      opacity: 0.2;
      filter: grayscale(100%);
      &:hover {
        opacity: 1;
        filter: none;
      }
    }
  }
  .description {
    color: rgba(#2c2d2a, 0.6);
    font-weight: 300;
    font-size: 14px;
    @include media('>=tablet') {
      font-size: 16px;
    }
  }
}

.icons-list-block {
  &.content {
    margin-bottom: 0;
    h2 {
      @include media('>=desktop') {
        font-size: 2.815rem;
        line-height: 1.22;
        font-weight: 400;
        margin: 0 0 1rem;
      }
    }
    h3 {
      margin-top: 0;
      @include media('>=desktop') {
        font-size: 26px;
        margin-bottom: 13px;
      }
    }
  }
  &.section {
    @include media('>=tablet') {
      padding: 5.55rem 1.5rem 7.1rem;
    }
  }
  .block-content {
    color: rgba(#2c2d2a, 0.7);
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 2.5rem;
    @include media('>=tablet') {
      margin-bottom: 3.5rem;
      line-height: 1.75;
      font-size: 20px;
    }
  }
  .columns {
    line-height: 1.65;
    margin-bottom: 2rem;
    @include media('>=tablet') {
      margin-bottom: 53px;
    }
    .column {
      margin-bottom: 15px;
      img {
        margin-bottom: 16px;
        @include media('<tablet') {
          max-width: 60px;
        }
      }
    }
  }
}

.two-columns-block {
  padding: 30px 1.5rem 40px;
  @include media('>=tablet') {
    padding: 50px 1.5rem 70px;
    line-height: 1.75;
  }
  @include media('>=desktop') {
    padding: 60px 15px 97px;
    font-size: 20px;
  }
  &.content {
    margin-bottom: 0;
    h2 {
      @include media('>=desktop') {
        font-size: 2.815rem;
        line-height: 1.22;
        font-weight: 400;
        margin: 0 0 0.9rem;
      }
    }
  }
  .columns {
    padding: 30px 0 15px;
    @include media('>=tablet') {
      padding: 40px 0 20px;
    }
    @include media('>=desktop') {
      padding: 68px 0 26px;
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
      .img-holder {
        @include media('>=tablet') {
          margin: 33px -17px 0 24px;
        }
        img {
          @include media('>=tablet') {
            transform: translate(-23px, -25px);
          }
        }
      }
      .text-holder {
        @include media('>=tablet') {
          padding-left: 40px;
          padding-right: 0;
        }
        @include media('>=desktop') {
          padding-left: 56px;
        }
      }
    }
    .column {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .img-holder {
    position: relative;
    border: 2px solid $primary;
    border-radius: 8px;
    margin: 0px 20px 0 0;
    @include media('>=tablet') {
      margin: 34px 25px 0 -16px;
    }
    img {
      border-radius: 8px;
      width: 100%;
      position: relative;
      vertical-align: top;
      transform: translate(15px, -15px);
      @include media('>=tablet') {
        transform: translate(23px, -25px);
      }
    }
  }
  .text-holder {
    margin-bottom: 2.5rem;
    @include media('>=tablet') {
      margin-bottom: 0;
      padding-right: 40px;
    }
  }
}

.post-list {
  & > div {
    border-radius: 8px;
    margin-bottom: 30px;
    padding: 1.5rem;
    background-color: #f8f9fb;
  }
  h2 {
    a {
      color: inherit;
      &:hover {
        color: $base-link-color;
      }
    }
  }
}

.reviews-section {
  @include media('>=tablet') {
    padding: 70px 0 100px;
    font-size: 20px;
  }
  &.content {
    margin-bottom: 0;
  }
  h2 {
    margin-bottom: 16px;
    @include media('>=tablet') {
      font-size: 45px;
      line-height: 55px;
      font-weight: 400;
    }
  }
}

.reviews-list {
  padding: 47px 8px 0;
  .review-item {
    h3 {
      margin: 0 0 2px;
    }
  }
}

.review-item {
  border: 1px solid #e5e5e5;
  background-color: #fefefe;
  border-radius: 10px;
  padding: 20px;
  @include media('>=tablet') {
    padding: 40px;
  }
  @include media('>=desktop') {
    padding: 20px;
  }
  @include media('>=fullhd') {
    padding: 40px;
  }
  h3 {
    color: #2c2d2a;
    font-size: 20px;
    line-height: 1.3;
    font-weight: 400;
    font-weight: 600;
    margin: 0 0 2px;
    @include media('>=tablet') {
      font-size: 25px;
      line-height: 1.5;
    }
  }
  h4 {
    color: rgba(#2c2d2a, 0.8);
    font-size: 18px;
    line-height: 1.4;
    font-weight: 500;
    margin-bottom: 0;
    @include media('>=tablet') {
      font-size: 22px;
    }
  }
  p {
    margin-bottom: 1em;
  }
  .quote {
    margin-bottom: 11px;
  }
  .rating {
    margin-bottom: 2px;
    &.rating-1 {
      .star:first-child() {
        path {
          fill: #ffc83d;
        }
      }
    }
    &.rating-2 {
      .star {
        &:first-child(),
        &:nth-child(2) {
          path {
            fill: #ffc83d;
          }
        }
      }
    }
    &.rating-3 {
      .star {
        &:first-child(),
        &:nth-child(2),
        &:nth-child(3) {
          path {
            fill: #ffc83d;
          }
        }
      }
    }
    &.rating-4 {
      .star {
        &:first-child(),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          path {
            fill: #ffc83d;
          }
        }
      }
    }
    &.rating-5 {
      .star {
        path {
          fill: #ffc83d;
        }
      }
    }
  }
  .text {
    color: rgba(#000000, 0.7);
    margin-bottom: 32px;
    font-style: italic;
    @include media('>=tablet') {
      font-size: 18px;
      line-height: 1.7;
    }
  }
  .bottom-block {
    position: relative;
    padding-right: 70px;
    @include media('>=tablet') {
      padding-right: 80px;
    }
    @include media('>=fullhd') {
      padding-right: 120px;
    }
  }
  .img-holder {
    position: absolute;
    bottom: -35px;
    right: 0;
    max-width: 65px;
    border-radius: 50%;
    overflow: hidden;
    @include media('>=tablet') {
      max-width: 75px;
      bottom: -60px;
    }
    @include media('>=fullhd') {
      right: 36px;
    }
    img {
      vertical-align: top;
    }
  }
}

.our-scientists-block.content {
  h2 {
    font-size: 2.815rem;
    font-weight: 400;
  }

  .scientist-name {
    font-weight: 600;
    margin: 1rem 0;
  }

  .scientist-title {
    margin: 0;
    font-weight: 350;
  }
}

.our-scientists-block {
  .columns {
    padding: 28px 0;
  }
}

.faq-block {
  ul {
    list-style-type: none;
    width: 100%;
    margin: 0;
  }

  li {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #cecece26;
    border-radius: 7px;
    text-align: left;
    margin: 1rem;
    padding: 1rem;
    color: #838fa5;
  }

  a {
    span {
      color: black;
      display: inline-block;
      width: 95%;
    }
  }

  i {
    text-align: right;
  }

  .faq-answer-section {
    margin: 1rem 0;
    width: 95%;
  }
}

.single-test {
  h1 {
    color: #4c5363;
    font-weight: 700;
  }

  h3 {
    color: #b45157;
    font-weight: 600;
    font-size: 26px;
  }

  .featured-img {
    width: 100%;
  }

  .price {
    color: #c24954;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
  }

  .step-description {
    font-size: 12px;
  }

  .step-circle {
    background-color: white;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    display: flex;
    position: relative;
  }

  .step-icon {
    height: 40px;
    margin: auto;
  }

  .step-count {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    color: #c24954;
  }

  .card-header-title {
    color: #4c5363;
    font-weight: 600;
    font-size: 23px;
  }

  .rotated-icon {
    transform: rotate(180deg);
  }
}

ul {
  font-size: 16px;
  line-height: 1.5;
}

.card-header-icon {
  color: #4c5363;
  font-weight: 600;
  font-size: 23px;
}

@media (max-width: 768px) {
  .single-test {
    .reverse-columns-mobile {
      flex-direction: column-reverse;
      display: flex;
    }

    .step-circle {
      height: 90px;
      width: 90px;
    }

    .step-icon {
      height: 30px;
    }

    .step-count {
      bottom: 6px;
    }

    .card-header-title {
      font-size: 21px;
    }
  }

  .card-header-icon {
    font-size: 21px;
  }
}

@media screen and (min-width: 1024px) {
  .mobile-link {
    display: none;
  }

  .our-scientists-block {
    .container {
      padding: 0 13rem;
    }
  }
}

.wistia-video-block {
  .container {
    max-width: 650px !important;
    border-radius: 8px;
    overflow: hidden;

    > p {
      display: none;
    }
  }
}

.carousel-video-container {
  .wistia_responsive_padding {
    top: 50%;
    transform: translateY(-50%);

    img {
      border-radius: 32px;
    }
  }
  height: 376px;
}

.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;

  > .main-image-container {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 376px;

    > .main-image {
      border-radius: 32px;
      max-width: 100%;
      max-height: 100%;
    }
  }

  > .thumbnail-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;

    > .thumbnails {
      display: flex;
      gap: 24px;

      > .thumbnail {
        width: 52px;
        height: 52px;
        object-fit: cover;
        cursor: pointer;
        border-radius: 8px;
      }

      > .thumbnail.thumbnail-active {
        border: 2px solid #c24954;
      }
    }

    > .arrow {
      cursor: pointer;
    }

    > .arrow.arrow-left {
      margin-right: 24px;
    }

    > .arrow.arrow-right {
      margin-left: 24px;
    }
  }
}

@media (min-width: 1216px) {
  .carousel-container {
    > .main-image-container {
      height: 432px;
    }
  }

  .carousel-video-container {
    height: 432px;
  }
}

@media (min-width: 1408px) {
  .carousel-container {
    > .main-image-container {
      height: 488px;
    }
  }

  .carousel-video-container {
    height: 488px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .carousel-container {
    > .thumbnail-container {
      > .thumbnails {
        gap: 8px;
      }

      > .arrow.arrow-left {
        margin-right: 8px !important;
      }

      > .arrow.arrow-right {
        margin-left: 8px !important;
      }
    }
  }
}

#FindALabModal {
  .modal-content {
    width: 80vw;
    max-width: 900px !important;
  }
}

.prenatal-findalab-container {
  .findALabControls {
    height: 98% !important;
  }
}

div.modal {
  z-index: 2147483647 !important;
}

@media only screen and (max-width: 768px) {
  #FindALabModal {
    .modal-content {
      width: 100% !important;
      margin: 0;
      max-height: none;
      height: calc(100dvh - 70px);
      bottom: -2px;
      position: absolute;
      overflow: hidden;
    }
  }
}
