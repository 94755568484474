// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/

$gray: #838fa4;
$black: #000;
$white: #fff;
$primary: #c24954;

$heavy-metal: #2a2c28;
$regent-gray: #858fa2;

$family-sans-serif: 'Poppins', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;

// // Body
$base-text-color: rgba(#2c2d2a, 0.7) !default;
$base-background-color: $white !default;
$font-size-base: 16px !default;
$line-height-base: 1.2 !default;

$vertical-rhythm: ($line-height-base * 1em);

$base-min-width: 320px;

// // Liks
$base-link-color: $primary;

// // Buttons
$btn-color: $white !default;
$btn-background: $primary !default;
$btn-padding: 0.4em 0.7em !default;

// // Forms
$form-element-padding: $btn-padding !default;
$form-element-border-color: $gray !default;
$form-element-focus-border-color: $black !default;
$placeholder-color: $gray !default;

$animation-speed: 0.3s;
