/*
Theme Name: Paternitylab
Author: Anonymous
Author URI:
Version: 1
Description: Paternitylab theme for Wordpress
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: paternitylab
Tags: one-column, two-columns
Theme URI:
*/

$breakpoints: (
  'xs-phone': 320px,
  'phone': 480px,
  'tablet': 768px,
  'desktop': 1024px,
  'widescreen': 1200px,
  'fullhd': 1408px,
);

// keywords
$media-expressions: (
  'screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)',
);

// ligatured operators ≥ ≤

@import 'vendors/include-media';
@import 'vendors/normalize';

@import 'bulma';

@import 'base/variables';
@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';
@import 'base/reset';
// @import 'base/cms-reset';
@import 'base/forms';
@import 'base/wp-reset';
@import 'base/typography';

@import 'layout/header';
@import 'layout/footer';
@import 'layout/common';
@import 'layout/buttons';
@import 'layout/print';
@import 'layout/language-switcher';

.resize-active {
  * {
    transition: none !important;
  }
}

.cookies-required-banner {
  background-color: $primary;
  color: white;
  max-width: 100% !important;
}

.enabled-cookies-button {
  border: 1px solid $primary;
  color: $primary;
  height: auto;

  &:hover {
    color: $black;
  }

  @include mobile {
    margin-top: 1em;
  }

  @include tablet {
    margin-left: 1em;
  }
}

//Force LiveChat and WonderChat to be on top of everything
#wonderchat-wrapper,
#chat-widget-container {
  z-index: 2147483647 !important;
}
