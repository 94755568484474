.language-switcher {
  position: relative;
  cursor: pointer;

  .current-language {
    display: flex;
    align-items: center;
  }

  img {
    width: 38px;
  }

  span,
  i {
    color: $heavy-metal;
    font-size: 1rem;
  }

  span {
    padding: 0 0.7rem 0 0.5rem;
    font-weight: 600;
  }

  .language-switcher-list-wrapper {
    display: none;
    position: absolute;
    padding-top: 1rem;
    z-index: 9999;

    ul {
      padding: 0.5rem 0 0.5rem 0.5rem;
      background: $white;
      box-shadow: 0 3px 6px rgba($black, 0.16);
      border-radius: 7px;
      width: max-content;

      li a {
        display: flex;
        justify-content: left;
        align-items: center;
        color: $heavy-metal;
        padding: 0.5rem 1rem 0.5rem 0.5rem;

        span {
          text-wrap: nowrap;
          font-weight: 400;
        }

        &:hover {
          background: rgba($regent-gray, 0.15);
        }
      }
    }
  }

  &:hover,
  &:active {
    .language-switcher-list-wrapper {
      display: block;
    }
  }
}

.language-switcher-header-mobile-wrapper {
  display: block;

  .language-switcher {
    margin: 0 0.5rem;

    .current-language {
      .currency {
        display: none;
      }

      img {
        margin: 0 0.5rem;
      }
    }

    .language-switcher-list-wrapper {
      right: 0;
    }
  }

  @include desktop {
    display: none;
  }
}

.language-switcher-header-mobile-menu-wrapper {
  display: block;
  padding: 0 0 1rem;

  .language-switcher {
    .current-language {
      justify-content: left;
    }

    .language-switcher-list-wrapper {
      left: 0;
    }
  }

  @include desktop {
    display: none;
  }
}

.language-switcher-header-desktop-wrapper {
  display: none;

  .language-switcher {
    .current-language {
      justify-content: center;
      padding: 0 3rem;
    }

    .language-switcher-list-wrapper {
      @media screen and (min-width: $desktop) and (max-width: $desktop + 100px) {
        right: 1rem;
      }

      @media screen and (min-width: $desktop + 101px) {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  @include desktop {
    display: block;
  }
}

.language-switcher-footer-wrapper {
  display: flex;
  justify-content: center;

  .language-switcher {
    padding: 0.5rem 0;

    .language-switcher-list-wrapper {
      left: 50%;
      transform: translateX(-50%);
    }

    .current-language {
      justify-content: center;
      padding: 0 3rem;

      i,
      span {
        color: $white;
      }
    }
  }
}
