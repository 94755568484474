.header {
  position: relative;
  z-index: 1;
  @include media('>=desktop') {
    padding: 10px 0;
  }
  .container {
    @include media('>=desktop') {
      display: flex;
      align-items: center;
    }
  }
}

.logo {
  a {
    display: block;
  }
  img {
    vertical-align: top;
    @include media('<tablet') {
      max-width: 170px;
    }
  }
}

.navbar-brand {
  background-color: $white;
  align-items: center;
  z-index: 1;
  @include media('<desktop') {
    padding: 8px 1rem;
  }
}

.nav-holder {
  @include media('<desktop') {
    background-color: $white;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 1.5rem;
    transform: translateY(-200%);
    transition: transform 0.3s ease-in-out;
  }
  @include media('>=desktop') {
    display: flex;
    align-items: center;
    max-width: 70%;
  }
}

ul.navbar-menu {
  @extend %listreset;
  flex-grow: 0;
  font-size: 20px;
  font-weight: 500;
  box-shadow: none;
  display: block;
  color: rgba(#2c2d2a, 0.9);
  margin-bottom: 20px;
  box-shadow: none;
  @include media('>=desktop') {
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  li {
    position: relative;
    margin-bottom: 15px;
    @include media('>=tablet') {
      padding: 4px 16px;
      margin-bottom: 0;
    }
    &:before {
      @include media('>=desktop') {
        background-color: $black;
        content: '';
        display: inline-block;
        vertical-align: top;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 1px;
        height: 12px;
        opacity: 0.3;
      }
    }
    &:first-child {
      &:before {
        display: none;
      }
    }
  }
  a {
    color: inherit;
    transition: color 0.3s ease-in-out;
    &:hover {
      color: $primary;
    }
  }
  .current_page_item {
    & > a {
      color: $primary;
    }
  }
  .drop-holder {
    display: none;
    transition: visibility 0.3s ease-in-out;
    background-color: #f8f9fb;
    padding: 10px 10px 5px;
    margin-top: 0.5rem;
    border-radius: 8px;
    @include media('>=desktop') {
      padding: 0;
      background-color: $primary;
      position: absolute;
      visibility: hidden;
      min-width: 200px;
      top: 100%;
    }
    li {
      padding: 2px 10px;
      margin-bottom: 5px;
      @include media('>=desktop') {
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        padding: 8px 10px;
        margin-bottom: 0;
      }
      &:before {
        display: none;
      }
      &:first-child {
        border: 0;
      }
    }
    a {
      @include media('>=desktop') {
        color: $white;
      }
      &:hover {
        color: $black;
      }
    }
    .drop-holder {
      top: 0;
      left: 100%;
    }
  }
  .hover {
    & > .drop-holder {
      @include media('>=desktop') {
        visibility: visible;
      }
    }
  }
}

.navbar {
  @include media('>=desktop') {
    max-width: 30%;
  }
}

a.phone {
  color: $gray;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
  @include media('>=tablet') {
    font-size: 18px;
  }
  @include media('>=fullhd') {
    font-size: 22px;
    letter-spacing: 1.1px;
  }
  &:hover {
    color: $primary;
    strong {
      color: $primary;
    }
  }
  strong {
    color: #2a2c28;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    transition: color 0.3s ease-in-out;
    @include media('>=tablet') {
      font-size: 24px;
    }
    @include media('>=fullhd') {
      font-size: 30px;
    }
  }
  .img {
    border: 2px solid $primary;
    background-color: $primary;
    border-radius: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    @include media('>=fullhd') {
      width: 60px;
      height: 54px;
    }
  }
  .text {
    display: flex;
    flex-direction: column;

    strong {
      white-space: nowrap;
    }
  }
}

.phone-holder {
  @include media('>=desktop') {
    padding-left: 16px;
  }
}

.navbar-burger {
  span {
    height: 2px;
    width: 24px;
    left: calc(50% - 12px);
  }
}

.nav-active {
  .nav-holder {
    @include media('<desktop') {
      transform: translateY(0);
    }
  }
  .navbar-burger {
    span {
      &:nth-child(1) {
        transform: translateY(5px) rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: translateY(-5px) rotate(-45deg);
      }
    }
  }
}
