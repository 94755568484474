html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  max-height: 1000000px;
}

body {
  color: $base-text-color;
  background: $base-background-color;
  font: #{$font-size-base}/#{$line-height-base} $family-sans-serif;
  min-width: $base-min-width;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include media('>=tablet') {
    font-size: 18px;
  }
}

// adaptive images
img {
  max-width: 100%;
  height: auto;
}

// google map fix
.gm-style img {
  max-width: none;
}

.clearfix {
  @extend %clearfix;
}

.listreset {
  @extend %listreset;
}

.text-center {
  text-align: center;
}
