@import "../utilities/mixins"

$section-padding: 3rem 1.5rem !default
$section-padding-medium: 9rem 1.5rem !default
$section-padding-large: 18rem 1.5rem !default

.section
  padding: $section-padding
  // Responsiveness
  +desktop
    // Sizes
    &.is-medium
      padding: $section-padding-medium
    &.is-large
      padding: $section-padding-large
