@import "../utilities/mixins"

$card-color: $text !default
$card-background-color: $scheme-main !default
$card-shadow: 0 0.5em 1em -0.125em rgba($scheme-invert, 0.1), 0 0px 0 1px rgba($scheme-invert, 0.02) !default
$card-radius: 0.25rem !default

$card-header-background-color: transparent !default
$card-header-color: $text-strong !default
$card-header-padding: 0.75rem 1rem !default
$card-header-shadow: 0 0.125em 0.25em rgba($scheme-invert, 0.1) !default
$card-header-weight: $weight-bold !default

$card-content-background-color: transparent !default
$card-content-padding: 1.5rem !default

$card-footer-background-color: transparent !default
$card-footer-border-top: 1px solid $border-light !default
$card-footer-padding: 0.75rem !default

$card-media-margin: $block-spacing !default

.card
  background-color: $card-background-color
  border-radius: $card-radius
  box-shadow: $card-shadow
  color: $card-color
  max-width: 100%
  position: relative

%card-item
  &:first-child
    border-top-left-radius: $card-radius
    border-top-right-radius: $card-radius
  &:last-child
    border-bottom-left-radius: $card-radius
    border-bottom-right-radius: $card-radius

.card-header
  @extend %card-item
  background-color: $card-header-background-color
  align-items: stretch
  box-shadow: $card-header-shadow
  display: flex

.card-header-title
  align-items: center
  color: $card-header-color
  display: flex
  flex-grow: 1
  font-weight: $card-header-weight
  padding: $card-header-padding
  &.is-centered
    justify-content: center

.card-header-icon
  align-items: center
  cursor: pointer
  display: flex
  justify-content: center
  padding: $card-header-padding

.card-image
  display: block
  position: relative
  &:first-child
    img
      border-top-left-radius: $card-radius
      border-top-right-radius: $card-radius
  &:last-child
    img
      border-bottom-left-radius: $card-radius
      border-bottom-right-radius: $card-radius

.card-content
  @extend %card-item
  background-color: $card-content-background-color
  padding: $card-content-padding

.card-footer
  @extend %card-item
  background-color: $card-footer-background-color
  border-top: $card-footer-border-top
  align-items: stretch
  display: flex

.card-footer-item
  align-items: center
  display: flex
  flex-basis: 0
  flex-grow: 1
  flex-shrink: 0
  justify-content: center
  padding: $card-footer-padding
  &:not(:last-child)
    +ltr-property("border", $card-footer-border-top)

// Combinations

.card
  .media:not(:last-child)
    margin-bottom: $card-media-margin
